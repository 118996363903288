export class enquiry {

    FULL_NAME:string='';
    
    COMPANY_NAME:string='';
    
    TELEPHONE_NO:any;
    
    FAX_NO:any;
    
    MOBILE_NO:any;
    
    EMAIL_ID:any;
    
    ADDRESS:any;
    
     CITY:any;
    
    COUNTRY:any;
    
    POSTAL_CODE:any;
    
    ENQUIRY_DETAILS:any;

    COUNTRY_ID:any;
    
    }