
export class contact {

NAME:string='';

SUBJECT:any;

DESCRIPTION:any;

MOBILE_NO:any;

EMAIL_ID:any;

DATE:any;

}


